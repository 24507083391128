/*
  ERROR PAGE TEMPLATE
  A template to display 404 pages
*/

import React from 'react'
import { motion } from 'framer-motion'

import PageLayout from '@base/layout/page-layout'
import Button from '@base/parts/button'

import { getGeneralData } from '@api/general-data'

const ErrorPage = ({ pageContext }) => {
  const data = {
    pageData: {
      ...pageContext.content
    }
  }

  // Setup Page specific data
  const generalData = getGeneralData(pageContext, data)
  const minifiedPageContext = generalData.pageContext
  const globalData = generalData.globalData
  const modalData = generalData.modalData
  const title = data?.pageData?.title
  const parentPage = null
  const disablePageBreadcrumbs = true
  const theme = pageContext.siteTheme
  const contentStyle = theme !== null && theme.content && theme.content.ErrorPage ? theme.content.ErrorPage : null

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs: disablePageBreadcrumbs,
        postTitle: title,
        postParent: [parentPage],
        strings: { back: globalData.stringsActions.back, home: globalData.stringsSlugs.homeTitle }
      }}
    >
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="container flex items-center flex-col py-32"
      >
        <h1 className={contentStyle !== null ? `text-${contentStyle.title}` : ''}>
          {title ? title : ('Not found')}
        </h1>
        <p className={contentStyle !== null ? `h3 text-${contentStyle.text}` : 'h3'}>
          {data?.pageData?.text ? data?.pageData?.text : ('The page you are looking for does not exists or has been moved.')}
        </p>
        <Button
          link={pageContext.locale !== 'en' ? (`/${pageContext.locale}`) : ('/')}
          trigger="internalLink"
          style="tertiary"
          size="lg"
          pageContext={pageContext}
          className="mt-8 lg:mt-16"
        >
          <div className="btn-content">
            <i className="btn-decorator btn-decorator-left" />
            <span>
              {data?.pageData?.button ? data?.pageData?.button : ('Back to home page')}
            </span>
            <i className="btn-decorator btn-decorator-right" />
          </div>
        </Button>
      </motion.section>
    </PageLayout>
  )
}

export default ErrorPage

export { Head } from '@base/layout/head'